import React from 'react'
import {Success} from 'ekyc-web-module';

const success = () => {

  const props ={
    baseUrl:'https://api3.singtel.com',
    ekycToken:'abccc',
    identityToken:'ads444'
  }
  return (
    <div>success
      <Success {...props} />
    </div>
  )
}

export default success